<template>
  <div class="text-left p-6">
    <h1 class="text-8xl font-bold">
      {{ $t("heading.main.pages.not_found.404") }}
    </h1>
    <p class="text-2xl">
      {{ $t("text.main.pages.not_found.not_found_message") }}
    </p>
    <div class="mt-6">
      <router-link
        :to="{ name: 'Home' }"
        class="mt-6 bg-white text-dark-green border border-dark-green hover:bg-dark-green hover:text-white p-1 px-10 text-center transition ease-in duration-150"
      >
        {{ $t("link.main.pages.not_found.go_home") }}
      </router-link>
    </div>
  </div>
</template>
